import { useTransition, type MouseEventHandler } from 'react'
import { NotificationsHistoryInfo } from '../services'
import { useOpenNotificationCard } from './use-open-notification-card'

export const useNotificationInfo = ({
  handleOpenNotification,
  notification,
  handleDeleteNotification,
  setNotification,
}: {
  notification: NotificationsHistoryInfo['notifications'][number]
  handleOpenNotification: (
    notification: NotificationsHistoryInfo['notifications'][number]
  ) => void
  handleDeleteNotification: (params: {
    id: string
    isRead: boolean
  }) => Promise<void>
  setNotification: (
    params: NotificationsHistoryInfo['notifications'][number]
  ) => void
}) => {
  const [isPendingDelete, startTransition] = useTransition()

  const setOpenNotificationCard = useOpenNotificationCard(
    (state) => state.setOpenCard
  )
  const onOpenNotification = () => {
    if (isPendingDelete) return
    setOpenNotificationCard(false)
    setNotification(notification)
    handleOpenNotification(notification)
  }

  const onDeleteNotification: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    if (isPendingDelete) return
    // @ts-ignore
    startTransition(async () => {
      await handleDeleteNotification({
        id: notification.id.toString(),
        isRead: notification.read,
      })
    })
  }

  return {
    states: { isPendingDelete },
    actions: {
      onOpenNotification,
      onDeleteNotification,
    },
  }
}
