import { initializeApp, type FirebaseOptions } from 'firebase/app'
import { setCookie } from 'cookies-next'
import { getMessaging, getToken } from 'firebase/messaging'
import localForage from 'localforage'
import { FCM_TOKEN } from 'libs'

export const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
}

export const webPushCertificateKeyPair =
  process.env.NEXT_PUBLIC_FIREBASE_WEB_PUSH_KEY_PAIR

export const firebaseCloudMessaging = {
  init: async () => {
    try {
      const app = initializeApp(firebaseConfig)
      const messaging = getMessaging(app)
      const status = await Notification.requestPermission()
      const tokenInLocalForage = await localForage.getItem(FCM_TOKEN)

      if (tokenInLocalForage !== null) {
        return tokenInLocalForage
      }

      if (status === 'granted') {
        const fcmToken = await getToken(messaging, {
          vapidKey: webPushCertificateKeyPair,
        })

        if (fcmToken) {
          await localForage.setItem(FCM_TOKEN, fcmToken)
          setCookie(FCM_TOKEN, fcmToken)
          return fcmToken
        }
      }
    } catch (error) {
      console.error(error)
      return null
    }
  },
}
