import {
  AdditionalBaseResponse,
  BaseApiResponse,
  LoginResponse,
  RefreshTokenResponse,
  isSuccess,
  request,
} from 'libs'
import {
  CredentialType,
  LoginSchema,
  PostRefreshBody,
  RequestOtpCodeSchema,
} from '../types/login.types.'

export const login = async (
  credentialType: CredentialType,
  body: LoginSchema | RequestOtpCodeSchema,
  appHeader: 'panel' | 'web' = 'web',
  lang: string = 'es-ES,es'
): Promise<AdditionalBaseResponse<LoginResponse>> => {
  const url = `/auth/${credentialType}-login`

  const response = await request.post(url, body, {
    headers: {
      app: appHeader,
      'Accept-Language': lang,
    },
  })

  const data: LoginResponse = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const logout = async ({
  appHeader = 'web',
  lang = 'es-ES,es',
  accessToken,
}: {
  appHeader?: 'panel' | 'web'
  lang?: string
  accessToken?: string
}): Promise<AdditionalBaseResponse<BaseApiResponse<object>>> => {
  const url = `/auth/logout`
  const response = await request.post(
    url,
    {},
    {
      headers: {
        'Accept-Language': lang,
        app: appHeader,
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  const data = await response.json()

  return {
    ...data,
    success: isSuccess(response.status),
    statusCode: response.status,
  }
}

export const refreshTokens = async (
  refreshTokenValue: string,
  appHeader: 'panel' | 'web' = 'web'
): Promise<AdditionalBaseResponse<RefreshTokenResponse>> => {
  const response = await request.get(`/auth/refresh`, {
    headers: {
      Authorization: `Bearer ${refreshTokenValue}`,
      app: appHeader,
    },
  })
  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const postRefreshTokens = async ({
  appHeader = 'web',
  body,
  refreshTokenValue,
}: {
  refreshTokenValue: string
  body: PostRefreshBody
  appHeader: 'panel' | 'web'
}): Promise<AdditionalBaseResponse<RefreshTokenResponse>> => {
  const response = await request.post(`/auth/refresh`, body, {
    headers: {
      Authorization: `Bearer ${refreshTokenValue}`,
      app: appHeader,
    },
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}
