import { create } from 'zustand'
import { NotificationCountResponse } from '../services'

type NotificationCountStoreState = {
  notificationCountResponse?: NotificationCountResponse
}

type NotificationCountStoreActions = {
  setNotificationCountResponse: (
    notificationCountResponse: NotificationCountResponse
  ) => void
}

export const useNotificationCountStore = create<
  NotificationCountStoreActions & NotificationCountStoreState
>((set) => ({
  setNotificationCountResponse: (notificationCountResponse) =>
    set({ notificationCountResponse }),
}))
