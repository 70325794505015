import { create } from 'zustand'

type LogoutDialogProps = {
  open?: boolean
}

type LogoutDialogActions = {
  setOpenDialog: (open: boolean) => void
}

export const useLogoutDialog = create<LogoutDialogProps & LogoutDialogActions>(
  (set) => ({
    open: false,
    setOpenDialog: (open) => set({ open }),
  })
)
