import { useState } from 'react'

export const useOtp = () => {
  const [isForm, setIsForm] = useState(true)
  const [otpCode, setOtpCode] = useState<string>()
  const [openDialog, setOpenDialog] = useState(false)
  const [otpCodeExpire, setOtpCodeExpire] = useState(false)

  const closeDialog = () => {
    setOpenDialog(false)
    setIsForm(true)
  }
  return {
    states: { isForm, otpCode, openDialog, otpCodeExpire },
    actions: {
      setIsForm,
      setOpenDialog,
      closeDialog,
      onOptInputChange: setOtpCode,
      setOtpCodeExpire,
    },
  }
}
