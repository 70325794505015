import localforage from 'localforage'
import { useRemittanceStore } from '@/services/remittance/remittance.store'
import { useShopStore } from '@/services/shop/shop.store'
import { useProfileStore } from '@/services'
import { useFlightsStore } from '@/services/flights/flights.store'

export const useClearCacheStorage = () => {
  const { resetShopCart } = useShopStore((state) => ({
    resetShopCart: state.resetShopCart,
  }))
  const resetRemittanceStore = useRemittanceStore(
    (state) => state.resetRemittanceStore
  )
  const resetUser = useProfileStore((state) => state.resetUser)
  const resetFlightStore = useFlightsStore((state) => state.resetFlightStore)

  const clearCacheStorage = (options?: { notRemoveFcm: boolean }) => {
    resetShopCart()
    resetRemittanceStore()
    resetFlightStore()
    resetUser()
    localStorage.clear()
    sessionStorage.clear()

    if (!options?.notRemoveFcm) {
      void localforage.removeItem('fcm_token')
    }
  }

  return {
    clearCacheStorage,
  }
}
