import { FC, PropsWithChildren } from 'react'
import { FormItem, Skeleton } from 'ui'
import { InputStyles } from 'ui/src/components/input/input.style'

export const LoginSkeletonLoader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="shadow-md bg-[#f5f5f5] md:w-[24.25rem] w-full">
      <div className="px-4 py-8 md:px-8">
        <div className="flex flex-col justify-center w-full h-full space-y-8">
          <div>
            <FormItem>
              <Skeleton className="w-[176px] h-4 bg-slate-300" />
              <Skeleton className={InputStyles()} />
            </FormItem>
            <div className="flex items-center gap-1 mt-2 sm:justify-start">
              <Skeleton className="h-6 w-[183px] bg-slate-300" />
            </div>
          </div>
          <Skeleton className="h-10 space-y-8 bg-slate-300" />
          {children}
        </div>
      </div>
    </div>
  )
}
