import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import type {
  OfacType,
  RemittanceRequest,
  ResumeType,
  SendData,
} from './remittance.type'

export type RemittanceState = {
  currentStep: number
  sendData: SendData | null
  resume: ResumeType | null
  remittance: RemittanceRequest | null
  ofacType: Partial<OfacType> | null
}

const defaultState: RemittanceState = {
  currentStep: 1,
  ofacType: null,
  resume: null,
  remittance: null,
  sendData: null,
}

export type RemittanceAction = {
  setCurrentStep: (currentStep: RemittanceState['currentStep']) => void
  setSendData: (sendData: RemittanceState['sendData']) => void
  setResume: (resume: RemittanceState['resume']) => void
  setRemittance: (remittance: RemittanceState['remittance']) => void
  setOfac: (ofac: RemittanceState['ofacType']) => void
  resetRemittanceStore: () => void
}

export const useRemittanceStore = create<RemittanceState & RemittanceAction>()(
  persist(
    (set) => ({
      ...defaultState,
      setCurrentStep: (currentStep) => set({ currentStep }),
      setSendData: (sendData) => set({ sendData }),
      setResume: (resume) => set({ resume }),
      setRemittance: (remittance) => set({ remittance }),
      setOfac: (ofac) =>
        set((state) => {
          return {
            ...state,
            ofacType: {
              ...state.ofacType,
              ...ofac,
              ofac: {
                ...state.ofacType?.ofac,
                ...ofac?.ofac,
              },
            },
          }
        }),
      resetRemittanceStore: () => set(defaultState),
    }),
    { name: 'remittance', storage: createJSONStorage(() => sessionStorage) }
  )
)
