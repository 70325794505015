import { isEmpty, isSuccess, request } from 'libs'
import type {
  NotificationCountResponse,
  NotificationDeleteResponse,
  NotificationReadResponse,
  NotificationsHistoryInfoResponse,
} from './notification.types'

type CommonHeaders = {
  accessToken?: string
  appHeader?: 'panel' | 'web'
  locale?: 'es' | 'en'
}

const getHeaders = ({
  accessToken,
  appHeader = 'web',
  locale = 'es',
}: CommonHeaders): HeadersInit => {
  return {
    Authorization: `Bearer ${accessToken}`,
    app: appHeader,
    'Accept-Language': locale,
  }
}

export const getNotificationCount = async ({
  accessToken,
  appHeader = 'web',
  locale = 'es',
}: CommonHeaders): Promise<NotificationCountResponse> => {
  const url = '/notification/count'

  const response = await request.get(url, {
    cache: 'no-store',
    headers: getHeaders({ accessToken, appHeader, locale }),
  })
  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const markReadNotification = async ({
  notificationId,
  accessToken,
  appHeader,
  locale,
}: {
  notificationId: string
} & CommonHeaders): Promise<NotificationReadResponse> => {
  const url = `/notification/${notificationId}`

  const response = await request.post(
    url,
    {},
    {
      headers: getHeaders({ accessToken, appHeader, locale }),
    }
  )
  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const deleteNotification = async ({
  notificationId,
  accessToken,
  appHeader,
  locale,
}: {
  notificationId: string
} & CommonHeaders): Promise<NotificationDeleteResponse> => {
  const url = `/notification/${notificationId}`

  const response = await request.delete(url, {
    headers: getHeaders({ accessToken, appHeader, locale }),
  })
  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}

export const getNotificationHistory = async ({
  accessToken,
  queryParams,
  appHeader,
  locale,
}: {
  queryParams: {
    order?: 'ASC' | 'DESC'
    page?: number
    take?: number
  }
} & CommonHeaders): Promise<NotificationsHistoryInfoResponse> => {
  const url = '/notification'
  const searchParams = new URLSearchParams()

  Object.entries(queryParams).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      searchParams.append(key, String(value))
    }
  })

  const response = await request.get(`${url}?${searchParams.toString()}`, {
    headers: getHeaders({ accessToken, appHeader, locale }),
    cache: 'no-store',
  })

  const data = await response.json()

  return {
    ...data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}
