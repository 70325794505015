'use client'

import { AdditionalBaseResponse, LoginResponse } from 'libs'
import { FC, PropsWithChildren } from 'react'
import {
  AlertDestructive,
  Button,
  Card,
  CardContent,
  EmailInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  USAPhoneInput,
} from 'ui'
import { useLogin } from '../hooks/use-login'
import OptCodeSection, { OptCodeSectionProps } from './otp-code-section'

type LoginFormProps = {
  onLogin?: (response: AdditionalBaseResponse<LoginResponse>) => void
  onLoginError?: (response: AdditionalBaseResponse<LoginResponse>) => void
  onRequestCode?: (response: AdditionalBaseResponse<LoginResponse>) => void
  emailQuestion: string
  phoneQuestion: string
  requestCodeButtonText: string
  enterButtonText: string
  continueButtonText: string
  phoneInputLabel: string
  spanText: string
  emailInputLabel: string
  appHeader?: 'panel' | 'web'
  lang?: string
  version: string
  translate?: (key: string) => string
} & Pick<OptCodeSectionProps, 'expirationAlertTitle' | 'headerTitle'>

export const LoginForm: FC<PropsWithChildren<LoginFormProps>> = ({
  children,
  requestCodeButtonText,
  emailQuestion,
  expirationAlertTitle,
  headerTitle,
  phoneQuestion,
  continueButtonText,
  enterButtonText,
  emailInputLabel,
  phoneInputLabel,
  spanText,
  appHeader = 'web',
  lang = 'es-ES,es',
  version,
  onLogin,
  onRequestCode,
  onLoginError,
  translate,
}) => {
  const {
    actions: {
      handleChangeInputType,
      handleLogin,
      handleChangeOtpCode,
      handleShowOtpCodeInput,
      handleRequestOtpCode,
      cleanErrorsMessages,
    },
    values: {
      form,
      isPhone,
      showInputOtpCode,
      isOtpExpired,
      otpCodeInputRef,
      otpMinutes,
      otpSeconds,
      alertErrors,
      isInvalidOtpCode,
    },
  } = useLogin({
    onLogin,
    onRequestCode,
    onLoginError,
    appHeader,
    lang,
    version,
  })

  const isDisabled =
    isInvalidOtpCode ||
    form.formState.isSubmitting ||
    (showInputOtpCode && isOtpExpired)

  return (
    <Card className="shadow-md bg-[#f5f5f5] md:w-[24.25rem] w-full">
      <CardContent className="px-4 py-8 md:px-8">
        <Form {...form}>
          <form
            autoComplete="off"
            noValidate
            className="flex flex-col justify-center w-full h-full space-y-8 "
            onSubmit={handleLogin}
          >
            {showInputOtpCode ? (
              <>
                <OptCodeSection
                  credentialQuestion={isPhone ? phoneQuestion : emailQuestion}
                  headerTitle={headerTitle}
                  ref={otpCodeInputRef}
                  credentialValue={
                    isPhone ? form.getValues('phone') : form.getValues('email')
                  }
                  expirationAlertTitle={expirationAlertTitle}
                  expirationOptMinutes={otpMinutes}
                  expirationOptSecond={otpSeconds}
                  onCredentialQuestionClick={() => {
                    handleShowOtpCodeInput('credentialQuestButton')
                  }}
                  handleRequestOtpCode={handleRequestOtpCode}
                  onOptCodeInputChange={handleChangeOtpCode}
                  isOtpExpired={isOtpExpired}
                  requestCodeButtonText={requestCodeButtonText}
                  isLoadingRequestCodeButton={form.formState.isSubmitting}
                />
                <AlertDestructive
                  errors={alertErrors}
                  translate={translate}
                  onClose={cleanErrorsMessages}
                />
              </>
            ) : (
              <div>
                {isPhone ? (
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{phoneInputLabel}</FormLabel>
                        <FormControl>
                          <USAPhoneInput {...field} autoFocus />
                        </FormControl>
                        <FormMessage t={translate} />
                      </FormItem>
                    )}
                  />
                ) : (
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{emailInputLabel}</FormLabel>
                        <FormControl>
                          <EmailInput
                            {...field}
                            type="email"
                            autoFocus
                            placeholder="user@domain.com"
                          />
                        </FormControl>
                        <FormMessage t={translate} />
                      </FormItem>
                    )}
                  />
                )}
                <div className="flex items-center gap-1 sm:justify-start ">
                  <span>{spanText}</span>
                  <Button
                    onClick={handleChangeInputType}
                    className="px-0 text-base text-black underline hover:text-primary"
                    variant="link"
                    type="button"
                  >
                    {isPhone ? emailInputLabel : phoneInputLabel}
                  </Button>
                </div>
              </div>
            )}
            <Button
              disabled={isDisabled}
              type="submit"
              isLoading={form.formState.isSubmitting && !isOtpExpired}
            >
              {showInputOtpCode ? continueButtonText : enterButtonText}
            </Button>

            {!showInputOtpCode && children}
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
