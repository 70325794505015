'use client'

import { useNotification } from '../hooks/use-notification'
import type { NotificationCountResponse } from '../services'
import { ReactNode } from 'react'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Loader2Icon,
  NotificationBadge,
  NotificationIcon,
} from 'ui'
import { cn } from 'utils-tailwindcss'
import { NotificationsHistoryInfo } from '../services'

type NotificationDropdownProps = {
  children: (params: {
    notifications: NotificationsHistoryInfo['notifications']
    handleDeleteNotification: (params: {
      isRead: boolean
      id: string
    }) => Promise<void>
    handleOpenNotification: (
      notification: NotificationsHistoryInfo['notifications'][number]
    ) => Promise<void>
  }) => ReactNode
  getAccessToken: () => Promise<string | undefined>
  notificationCount?: NotificationCountResponse
  loadMoreButtonText: string
  noNotificationsText: string
  appHeader?: 'panel' | 'web'
  locale?: 'es' | 'en'
}

export const NotificationDropdown = ({
  children,
  notificationCount: notificationCountResponse,
  loadMoreButtonText,
  noNotificationsText,
  getAccessToken,
  appHeader = 'web',
  locale = 'es',
}: NotificationDropdownProps) => {
  const {
    actions: {
      handleOpenCard,
      handleDeleteNotification,
      handleLoadMore,
      handleOpenNotification,
    },
    states: {
      openCard,
      count,
      notifications,
      isLoading,
      disabledLoadMoreButton,
    },
  } = useNotification({
    notificationCount: notificationCountResponse?.data
      ? Number(notificationCountResponse.data)
      : 0,
    getAccessToken,
    appHeader,
    locale,
  })

  return (
    <DropdownMenu onOpenChange={handleOpenCard} open={openCard}>
      <DropdownMenuTrigger asChild>
        <Button
          className="relative font-normal !ring-0 !ring-offset-0 !outline-0 !border-none"
          size="icon"
          variant="ghost"
        >
          <NotificationBadge
            className={cn('top-0 left-4', isLoading && 'bg-white')}
            variant="secondary"
          >
            {isLoading ? (
              <Loader2Icon className="w-full h-full animate-spin text-primary stroke-[4px]" />
            ) : (
              count
            )}
          </NotificationBadge>
          <NotificationIcon />
        </Button>
      </DropdownMenuTrigger>
      {isLoading && !notifications.length ? null : (
        <DropdownMenuContent
          className="relative p-0 w-[22.175rem] sm:w-[35rem]  overflow-y-auto max-h-[500px] pb-[35px] transition-all"
          sideOffset={15}
        >
          {!notifications.length && (
            <p className="px-4 pt-4 text-center">{noNotificationsText}</p>
          )}

          {children({
            notifications,
            handleDeleteNotification,
            handleOpenNotification,
          })}

          {notifications.length > 0 && !disabledLoadMoreButton && (
            <button
              className="fixed bottom-0 left-0 right-0 z-10 flex justify-center p-1 font-bold transition-all md:w-full rounded-b-md bg-slate-200 hover:bg-slate-300 item-center disabled:cursor-not-allowed disabled:bg-slate-100 disabled:hover:bg-slate-100"
              disabled={disabledLoadMoreButton}
              onClick={handleLoadMore}
              type="button"
            >
              {isLoading && (
                <Loader2Icon className="self-center w-4 h-4 mr-1 animate-spin" />
              )}
              {loadMoreButtonText}
            </button>
          )}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  )
}
