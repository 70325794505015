'use client'

import { Locale } from 'date-fns'
import { formatNotificationDateToNow } from 'libs'
import { useMemo, type FC } from 'react'
import { Bell, SERVICE_ICONS, Trash2Icon, Loader2Icon } from 'ui'
import { cn } from 'utils-tailwindcss'
import { useNotificationInfo } from '../hooks/use-notification-info'
import { NotificationsHistoryInfo } from '../services'

type NotificationInfoBlockProps = {
  notification: NotificationsHistoryInfo['notifications'][number]
  handleDeleteNotification: (params: {
    id: string
    isRead: boolean
  }) => Promise<void>
  handleOpenNotification: (
    notification: NotificationsHistoryInfo['notifications'][number]
  ) => void
  setNotification: (
    params: NotificationsHistoryInfo['notifications'][number]
  ) => void
  locale: Locale
}

export const NotificationInfoBlock: FC<NotificationInfoBlockProps> = ({
  locale,
  notification,
  handleDeleteNotification,
  handleOpenNotification,
  setNotification,
}) => {
  const {
    states: { isPendingDelete },
    actions: { onOpenNotification, onDeleteNotification },
  } = useNotificationInfo({
    notification,
    handleOpenNotification,
    handleDeleteNotification,
    setNotification,
  })

  const dateLocale = locale
  const Icon = useMemo(
    () => SERVICE_ICONS(notification.meta?.service ?? 'OTHER'),
    [notification.meta?.service]
  )

  return (
    <div
      className={cn(
        'flex items-center justify-between px-4 py-1 space-x-1 border-b',
        !notification.read ? 'hover:bg-accent' : 'hover:bg-muted'
      )}
      onClick={onOpenNotification}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onOpenNotification()
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center w-3/4 gap-2 sm:w-[55%]">
        <div
          className={cn(
            'p-2 rounded-full',
            !notification.read ? 'bg-primary' : 'bg-slate-300'
          )}
        >
          <Icon
            className="w-5 h-5"
            color={!notification.read ? '#E9E9E9' : '#353c46'}
          />
        </div>
        <div className="w-full space-y-1">
          <p
            className={cn(
              'text-base font-bold ellipsis w-auto',
              !notification.read ? 'text-primary' : 'text-[#353c46]'
            )}
          >
            {notification.title}
          </p>
          <p
            className={cn(
              'text-sm ellipsis',
              !notification.read ? 'text-green-700' : 'text-slate-400'
            )}
          >
            {notification.body}
          </p>
          <p className="block text-sm sm:hidden text-muted-foreground">
            {formatNotificationDateToNow({
              createdAt: notification.createdAt,
              locale: dateLocale,
            })}
          </p>
        </div>
      </div>
      <div className="flex flex-col flex-1 gap-1">
        <div className="flex flex-col items-end justify-end gap-2 sm:flex-row sm:whitespace-nowrap">
          <p className="hidden text-sm sm:block text-muted-foreground">
            {formatNotificationDateToNow({
              createdAt: notification.createdAt,
              locale: dateLocale,
            })}
          </p>
          {!notification.read && (
            <Bell className={cn('w-5 h-5 fill-primary stroke-primary')} />
          )}
        </div>
        {!isPendingDelete ? (
          <button
            className="ml-auto hover:scale-110"
            id="delete-notification-button"
            onClick={onDeleteNotification}
            onTouchStart={(e) => e.stopPropagation()}
            type="button"
          >
            <Trash2Icon className="w-5 h-5 text-destructive" />
          </button>
        ) : (
          <Loader2Icon className="w-5 h-5 block ml-auto animate-spin text-destructive stroke-[4px]" />
        )}
      </div>
    </div>
  )
}
