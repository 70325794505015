import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

export const useListenerToken = <TCookies extends Record<string, string>>(): {
  cookies: Partial<TCookies>
} => {
  const cookiesInstance = new Cookies()
  const [cookies, setCookies] = useState(() =>
    cookiesInstance.getAll({ doNotUpdate: true })
  )

  useEffect(() => {
    const onChange = () => {
      const newCookies = cookiesInstance.getAll({
        doNotUpdate: true,
      })
      setCookies(newCookies)
    }

    cookiesInstance.addChangeListener(onChange)
    return () => {
      cookiesInstance.removeChangeListener(onChange)
    }
  }, [])

  return {
    cookies,
  }
}
