import type { MessageEventData, NotificationMetaWithImage } from 'notifications'
import {
  getNotificationCount,
  useNotificationCountStore,
  useNotificationStore,
} from 'notifications'
import { useEffect, useRef } from 'react'
import type { DeepPartial } from 'react-hook-form'
import { getCookie } from 'cookies-next'
import { ACCESS_TOKEN } from 'libs'
import { firebaseCloudMessaging } from '@/shared/utils/firebase.utils'

async function setToken() {
  try {
    // TODO: Solve error that is send to sentry
    const token = await firebaseCloudMessaging.init()
    console.log(token)
  } catch (error) {
    console.log(error)
  }
}

const initFirebaseCloudMessaging = () => {
  const timeout = setTimeout(setToken, 60000)
  return () => clearTimeout(timeout)
}

// TODO: Pass this to notifications packages
export const usePushNotification = () => {
  const { setNotification } = useNotificationStore()
  const firebaseMessageId = useRef<string>()
  const content = useRef<string>()

  const setNotificationCountResponse = useNotificationCountStore(
    (state) => state.setNotificationCountResponse
  )
  const updateNotificationCount = async () => {
    const accessToken = getCookie(ACCESS_TOKEN)
    if (!accessToken) return

    const notificationCountResponse = await getNotificationCount({
      accessToken,
      appHeader: 'panel',
    })

    setNotificationCountResponse(notificationCountResponse)
  }

  useEffect(() => {
    initFirebaseCloudMessaging()

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener(
        'message',
        async (event: MessageEvent<DeepPartial<MessageEventData>>) => {
          console.log('event for the service worker', event)

          const notificationPayload = event.data.firebaseMessaging?.payload
          const notificationData = notificationPayload?.data
          const id = notificationPayload?.fcmMessageId
          const title = notificationPayload?.notification?.title || ''
          const body = notificationPayload?.notification?.body || ''
          const imgUrl = notificationPayload?.notification?.image || ''
          const titleWithBody = `${title} - ${body}`.toLowerCase()
          const isSameContent = titleWithBody === content.current

          if (isSameContent) {
            return
          }

          firebaseMessageId.current = id
          content.current = titleWithBody

          document.addEventListener('visibilitychange', () => {
            // TODO: Set notification badge if app is in background
            if (document.visibilityState !== 'visible') {
              console.log("I'm not visible")
            }
          })

          setNotification({
            title,
            body,
            meta: {
              imgUrl,
              serviceKey: notificationData?.serviceKey || '',
              service: notificationData?.entity || '',
              history: notificationData?.history === 'true',
              serviceDetailsId: notificationData?.serviceDetailsId || '',
              butonLabel: notificationData?.buttonLabel || '',
              serviceEnabled: !!notificationData?.serviceEnabled,
            } as NotificationMetaWithImage,
          })

          const registration = await navigator.serviceWorker.getRegistration()

          if (title) {
            await Promise.allSettled([
              updateNotificationCount(),
              registration?.showNotification(title, {
                body,
                image: imgUrl,
              } as NotificationOptions),
            ])
          }
          content.current = ''
        }
      )
    }
  })
}
