import { zodResolver } from '@hookform/resolvers/zod'
import { addSeconds } from 'date-fns'
import {
  AdditionalBaseResponse,
  LoginResponse,
  getOtpTime,
  padWithZero,
} from 'libs'
import { useEffect, useRef, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTimer } from 'react-timer-hook'
import {
  deviceSchema,
  loginFormSchema,
  otpCodeSchema,
  requestOtpCodeSchema,
} from '../schemas/login.schema'
import { login } from '../services/login.service'
import type {
  CredentialType,
  LoginFormSchema,
  LoginSchema,
  RequestOtpCodeSchema,
} from '../types/login.types.'
import { useToken } from './use-token'
import { useDevice } from './use-device'

type UseLoginParams = {
  onLogin?: (response: AdditionalBaseResponse<LoginResponse>) => void
  onLoginError?: (response: AdditionalBaseResponse<LoginResponse>) => void
  onRequestCode?: (response: AdditionalBaseResponse<LoginResponse>) => void
  appHeader?: 'panel' | 'web'
  lang?: string
  version: string
}

export const useLogin = ({
  onLogin,
  onRequestCode,
  onLoginError,
  appHeader = 'web',
  lang = 'es-ES,es',
  version,
}: UseLoginParams) => {
  const [isPhone, setIsPhone] = useState(true)
  const [otpCode, setOtpCode] = useState<string | undefined>(undefined)
  const [showOtpCodeInput, setShowOtpCodeInput] = useState(false)
  const [isOtpExpired, setIsOtpExpired] = useState(false)
  const [otpInputErrorMessage, setOtpInputErrorMessage] = useState('')
  const [deviceInfoError, setDeviceInfoError] = useState('')
  const { deviceInfo } = useDevice(version)
  const { updateTokens } = useToken()
  const otpCodeInputRef = useRef<any>(null)
  const form = useForm<LoginFormSchema>({
    resolver: zodResolver(loginFormSchema),
    values: isPhone
      ? {
          phone: '',
        }
      : {
          email: '',
        },
  })
  const {
    seconds: otpSeconds,
    minutes: otpMinutes,
    start: startTimerOtp,
    restart: reStartTimerOtp,
    pause: pauseOtp,
    isRunning: isRunningOtp,
  } = useTimer({
    expiryTimestamp: addSeconds(new Date(), getOtpTime(true)),
    onExpire: () => {
      setIsOtpExpired(true)
    },
    autoStart: false,
  })

  useEffect(() => {}, [])

  useEffect(() => {
    if (!isPhone && showOtpCodeInput) {
      reStartTimerOtp(addSeconds(new Date(), getOtpTime(isPhone)))
    }
  }, [showOtpCodeInput, isPhone])

  const credentialType: CredentialType = isPhone ? 'phone' : 'mail'
  const alertErrors = [deviceInfoError, otpInputErrorMessage].filter(Boolean)
  const isInvalidOtpCode = !otpCode && showOtpCodeInput

  const cleanErrorsMessages = () => {
    setOtpInputErrorMessage('')
    setDeviceInfoError('')
  }

  const handleChangeInputType = () => {
    setIsPhone(!isPhone)
    setIsOtpExpired(false)

    form.reset()
    form.clearErrors(['email', 'phone'])
  }

  const handleChangeOtpCode = (code: string) => {
    cleanErrorsMessages()
    setOtpCode(code)
  }

  const handleShowOtpCodeInput = (
    buttonType: 'credentialQuestButton' | 'continueButton',
    show = false
  ) => {
    const seconds = getOtpTime(isPhone)
    const isContinueButton =
      buttonType === 'continueButton' && !isRunningOtp && show

    if (buttonType === 'credentialQuestButton' && !show) {
      pauseOtp()
      setIsOtpExpired(false)
    }

    if (isContinueButton && otpSeconds < seconds) {
      reStartTimerOtp(addSeconds(new Date(), getOtpTime(isPhone)))
    }

    if (isContinueButton && otpSeconds === seconds) {
      startTimerOtp()
    }

    setShowOtpCodeInput(show)
  }

  const clearOtpCodeInput = () => otpCodeInputRef.current?.clear()

  const handleRequestOtpCode: SubmitHandler<LoginFormSchema> = async (
    formData
  ) => {
    if (!isOtpExpired) return
    clearOtpCodeInput()
    const device = deviceInfo.current

    const safeParseDevice = deviceSchema.safeParse({ device })

    if (!safeParseDevice.success) {
      const deviceError =
        safeParseDevice.error.flatten().fieldErrors?.device?.[0]
      return setDeviceInfoError(deviceError ?? '')
    }

    const body: RequestOtpCodeSchema = {
      ...formData,
      device: safeParseDevice.data.device,
    }

    const safeParseBody = requestOtpCodeSchema.safeParse(body)

    if (!safeParseBody.success) {
      const errors = safeParseBody.error.flatten()?.fieldErrors
      return setDeviceInfoError(errors?.device?.[0] ?? '')
    }

    const loginResponse = await login(
      credentialType,
      safeParseBody.data,
      appHeader,
      lang
    )

    if (loginResponse.success && showOtpCodeInput) {
      reStartTimerOtp(addSeconds(new Date(), getOtpTime(isPhone)))
      setIsOtpExpired(false)
      cleanErrorsMessages()
    }
    onRequestCode && onRequestCode(loginResponse)
  }

  const handleLogin: SubmitHandler<LoginFormSchema> = async (formData) => {
    const safeParseOtpCode = otpCodeSchema.safeParse({ otpCode })

    if (showOtpCodeInput && !safeParseOtpCode?.success) {
      const otpError =
        safeParseOtpCode.error.flatten().fieldErrors?.otpCode?.[0]
      return setOtpInputErrorMessage(otpError ?? '')
    }

    const device = deviceInfo.current

    const safeParseDevice = deviceSchema.safeParse({ device })

    if (!safeParseDevice.success) {
      const deviceError =
        safeParseDevice.error.flatten().fieldErrors?.device?.[0]
      return setDeviceInfoError(deviceError ?? '')
    }

    const body: RequestOtpCodeSchema | LoginSchema = {
      ...formData,
      device: safeParseDevice.data.device,
    }

    if (showOtpCodeInput && safeParseOtpCode.success) {
      ;(body as LoginSchema).otpCode = safeParseOtpCode.data.otpCode
    }

    const loginResponse = await login(credentialType, body, appHeader, lang)

    if (loginResponse.errorsDetails?.code === 'PO_08') {
      return form.setError('phone', {
        message: 'global.formErrors.invalidPhone',
      })
    }

    if (loginResponse.success && !showOtpCodeInput) {
      handleShowOtpCodeInput('continueButton', true)
    } else if (loginResponse.success && showOtpCodeInput) {
      cleanErrorsMessages()
      updateTokens({
        accessToken: loginResponse.data.accessToken,
        refreshToken: loginResponse.data.refreshToken,
        fcmToken: body.device.fcmToken,
      })

      onLogin && onLogin(loginResponse)
    }

    if (!loginResponse.success) {
      onLoginError && onLoginError(loginResponse)
    }
  }

  return {
    values: {
      isPhone,
      form,
      otpInputErrorMessage,
      deviceInfoError,
      showInputOtpCode: showOtpCodeInput,
      isOtpExpired,
      otpMinutes: padWithZero(otpMinutes),
      otpSeconds: padWithZero(otpSeconds),
      isInvalidOtpCode,
      otpCode,
      alertErrors,
      otpCodeInputRef,
    },
    actions: {
      cleanErrorsMessages,
      handleChangeInputType,
      handleLogin: form.handleSubmit(handleLogin),
      handleChangeOtpCode,
      handleShowOtpCodeInput,
      handleRequestOtpCode: form.handleSubmit(handleRequestOtpCode),
    },
  }
}
