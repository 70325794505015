import { Button, OtpCodeInput } from 'ui'
import CodeExpireAlert from './code-expire-alert'
import { BaseSyntheticEvent, Ref, forwardRef } from 'react'

export type OptCodeSectionProps = {
  headerTitle: string
  credentialValue: string
  onCredentialQuestionClick: () => void
  credentialQuestion: string
  handleRequestOtpCode: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>
  onOptCodeInputChange: (code: string) => void
  expirationAlertTitle: string
  expirationOptMinutes: string
  expirationOptSecond: string
  isOtpExpired: boolean
  requestCodeButtonText: string
  isLoadingRequestCodeButton?: boolean
}

const OptCodeSection = (
  {
    credentialQuestion,
    credentialValue,
    expirationAlertTitle,
    expirationOptMinutes,
    expirationOptSecond,
    headerTitle,
    isOtpExpired,
    isLoadingRequestCodeButton,
    requestCodeButtonText,
    onCredentialQuestionClick,
    onOptCodeInputChange,
    handleRequestOtpCode,
  }: OptCodeSectionProps,
  ref: Ref<any>
) => {
  return (
    <>
      <div className="flex flex-col items-center text-center">
        <p>{headerTitle}</p>
        <p>{credentialValue}</p>
        <Button
          className="text-destructive"
          onClick={onCredentialQuestionClick}
          type="button"
          variant="ghost"
        >
          {credentialQuestion}
        </Button>
        {isOtpExpired ? (
          <Button
            onClick={handleRequestOtpCode}
            type="button"
            variant="ghost"
            isLoading={isLoadingRequestCodeButton}
          >
            {requestCodeButtonText}
          </Button>
        ) : (
          <CodeExpireAlert
            titleAlert={expirationAlertTitle}
            otpMinutes={expirationOptMinutes}
            otpSeconds={expirationOptSecond}
          />
        )}
      </div>
      {!isOtpExpired && (
        <OtpCodeInput ref={ref} onChange={onOptCodeInputChange} />
      )}
    </>
  )
}

export default forwardRef(OptCodeSection)
