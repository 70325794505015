import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import type { FlightSteps } from '@/app/[locale]/(default)/(services)/flights/_types'
import type { Nill } from '@/shared'
import type {
  FlightsFilterSchema,
  FlightsOffer,
  Offer,
  DocumentType,
  PassengerInfo,
  ContactInfo,
  BookingOrder,
} from './flights.types'
import { defaultFlightState, defaultPassengerData } from './constants'
import { PassengerActionType } from './flights.schema'

const adjustPassengers = (
  prev: PassengerInfo[],
  count: number,
  type: PassengerActionType
) => {
  if (prev.length > count) {
    prev.splice(count)
  } else if (prev.length < count) {
    const diff = count - prev.length
    prev.push(
      ...Array.from({ length: diff }, () => ({
        ...defaultPassengerData,
        type,
        consent: false,
        hasOptionalDocument: false,
      }))
    )
  }
  return prev
}

export type FlightStoreState = {
  currentStep: FlightSteps
  flightsOffers: FlightsOffer | null
  selectedFlightOffer:
    | (Offer & {
        referenceId: string
      })
    | null
  searchFlightsFormData: Nill<FlightsFilterSchema>
  passengersDocumentTypes: DocumentType[]
  passengers: PassengerInfo[]
  contactInfo: ContactInfo
  bookingOrder: BookingOrder | null
  searchFlightsFormDataExpirationDate: string
}

export type FlightsStoreActions = {
  setCurrentStep: (currentStep: FlightSteps) => void
  setSelectedFlightOffer: (
    offer: FlightStoreState['selectedFlightOffer']
  ) => void
  setFlightsOffers: (offers: FlightsOffer | null) => void
  setSearchFlightsFormData: (data: Nill<FlightsFilterSchema>) => void
  setPassengersDocumentTypes: (data: DocumentType[]) => void
  resetFlightStore: () => void
  setPassengers: (data: {
    passengers: PassengerInfo[]
    contactInfo: ContactInfo
  }) => void
  setBookingOrder: (order: BookingOrder | null) => void
}

export const useFlightsStore = create<FlightStoreState & FlightsStoreActions>()(
  persist(
    (set) => ({
      ...defaultFlightState,
      setPassengersDocumentTypes: (data) =>
        set({ passengersDocumentTypes: data }),
      setFlightsOffers: (offers) => set({ flightsOffers: offers }),
      setCurrentStep: (currentStep) => set({ currentStep }),
      setSearchFlightsFormData: (data) =>
        set((state) => {
          const dateToExpire = new Date()
          dateToExpire.setHours(dateToExpire.getHours() + 1)
          const searchFlightsFormDataExpirationDate = dateToExpire.toISOString()

          const { passengers: searchedPassengers } = data
          if (!searchedPassengers)
            return {
              searchFlightsFormData: data,
              passengers: [],
              searchFlightsFormDataExpirationDate,
            }

          const prevPassengers = state.passengers
          if (
            JSON.stringify(searchedPassengers) ===
              JSON.stringify(state.searchFlightsFormData.passengers) &&
            prevPassengers.length > 0
          ) {
            return {
              searchFlightsFormData: data,
              passengers: prevPassengers,
              searchFlightsFormDataExpirationDate,
            }
          }

          const prevAdultPassengers = adjustPassengers(
            prevPassengers.filter((p) => p.type === PassengerActionType.ADULT),
            searchedPassengers[PassengerActionType.ADULT],
            PassengerActionType.ADULT
          )

          const prevChildPassengers = adjustPassengers(
            prevPassengers.filter((p) => p.type === PassengerActionType.CHILD),
            searchedPassengers[PassengerActionType.CHILD],
            PassengerActionType.CHILD
          )

          const prevInfantPassengers = adjustPassengers(
            prevPassengers.filter((p) => p.type === PassengerActionType.INFANT),
            searchedPassengers[PassengerActionType.INFANT],
            PassengerActionType.INFANT
          )

          const passengers = [
            ...prevAdultPassengers,
            ...prevChildPassengers,
            ...prevInfantPassengers,
          ]

          return {
            searchFlightsFormData: data,
            passengers,
            searchFlightsFormDataExpirationDate,
          }
        }),
      setSelectedFlightOffer: (offer) => set({ selectedFlightOffer: offer }),
      resetFlightStore: () => set(defaultFlightState),
      setPassengers: (data) =>
        set({ passengers: data.passengers, contactInfo: data.contactInfo }),
      setBookingOrder: (order) => set({ bookingOrder: order }),
    }),
    {
      name: 'flights',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
