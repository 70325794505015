import { FC } from 'react'

type ExpireAlertProps = {
  otpMinutes?: string
  otpSeconds?: string
  titleAlert: string
}
const CodeExpireAlert: FC<ExpireAlertProps> = ({
  titleAlert,
  otpMinutes = '00',
  otpSeconds = '00',
}) => {
  return (
    <p>
      {titleAlert}{' '}
      <span className="text-secondary">
        {otpMinutes}:{otpSeconds}
      </span>
    </p>
  )
}

export default CodeExpireAlert
