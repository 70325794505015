import { create } from 'zustand'

export type OpenNotificationCardState = {
  openCard: boolean
}

export type OpenNotificationCardActions = {
  setOpenCard: (openCard: boolean) => void
}

export const useOpenNotificationCard = create<
  OpenNotificationCardActions & OpenNotificationCardState
>((set) => ({
  openCard: false,
  setOpenCard: (openCard) => set({ openCard }),
}))
