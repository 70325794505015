import { create } from 'zustand'
import { NotificationData } from '../services'

type NotificationStoreProps = {
  notification: NotificationData | null
}

type NotificationStoreAction = {
  setNotification: (notification: NotificationData | null) => void
}

export const useNotificationStore = create<
  NotificationStoreProps & NotificationStoreAction
>((set) => ({
  notification: null,
  setNotification: (notification) => set({ notification }),
}))
