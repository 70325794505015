import type { Nill } from '@/shared'
import { EmptyLocation } from '@/components/address-autocomplete/constant'
import { ItineraryTypeEnum, PassengerActionType } from './flights.schema'
import type { FlightStoreState } from './flights.store'
import type {
  ContactInfo,
  FlightsFilterSchema,
  PassengerInfo,
} from './flights.types'

export const defaultSearchFlightsFormData: Nill<FlightsFilterSchema> = {
  itineraryType: ItineraryTypeEnum.ROUND_TRIP,
  passengers: {
    [PassengerActionType.ADULT]: 1,
    [PassengerActionType.CHILD]: 0,
    [PassengerActionType.INFANT]: 0,
  },
  departureDate: new Date(),
  returnDate: null,
  destinationCity: null,
  originCity: null,
}

export const defaultContactInfo: ContactInfo = {
  email: '',
  phone: '',
  lastName: '',
  secondSurname: '',
  country: EmptyLocation,
  city: EmptyLocation,
  address: '',
  firstName: '',
  postalCode: '',
  state: EmptyLocation,
  secondName: '',
}

export const defaultFlightState: FlightStoreState = {
  currentStep: 'search',
  flightsOffers: null,
  selectedFlightOffer: null,
  searchFlightsFormData: defaultSearchFlightsFormData,
  passengersDocumentTypes: [],
  passengers: [],
  contactInfo: defaultContactInfo,
  bookingOrder: null,
  searchFlightsFormDataExpirationDate: '',
}

export const defaultPassengerData: PassengerInfo = {
  firstName: '',
  lastName: '',
  secondName: '',
  secondSurname: '',
  gender: '',
  phone: '',
  email: '',
  hasOptionalDocument: false,
  mainDocument: '',
  documentNumber: '',
  expirationDate: '',
  documentIssuedBy: EmptyLocation,
  documentNationality: EmptyLocation,
  secondaryDocument: '',
  secondaryDocumentNumber: '',
  secondaryExpirationDate: '',
  secondaryDocumentIssuedBy: EmptyLocation,
  consent: false,
}
