import { DeviceInfoResponse, FCM_TOKEN, getDeviceInfo } from 'libs'
import localForage from 'localforage'
import { useEffect, useRef } from 'react'

export const useDevice = (version: string) => {
  const deviceInfo = useRef<
    DeviceInfoResponse & { version: string; fcmToken?: string }
  >()

  useEffect(() => {
    const userAgent = navigator.userAgent
    const getDeviceData = async () => {
      const deviceData = getDeviceInfo({ userAgent, version })
      const token = await localForage.getItem<string>(FCM_TOKEN)

      deviceInfo.current = {
        ...deviceData,
        version,
        fcmToken: token || undefined,
      }
    }

    void getDeviceData()
  }, [])

  return { deviceInfo }
}
