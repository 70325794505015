import { useToken } from 'authentication'
import { startHolyLoader } from 'holy-loader'
import { useRouter } from '@/navigation'
import { WebRoutes } from '@/shared'
import pkg from '../../../../package.json'
import { useClearCacheStorage } from './use-clear-cache-storage'

export const useHandlerTokens = (props?: {
  noLogout?: boolean
  onError?: (error?: unknown) => void
}) => {
  const router = useRouter()
  const { clearCacheStorage } = useClearCacheStorage()

  const initCleanup = () => {
    startHolyLoader()
    clearCacheStorage()
  }

  // TODO: Figure out how to avoid the execution of the next functions if this function is executed
  const handleLogoutByUnauthorized = (deleteTokens?: () => void) => {
    initCleanup()
    deleteTokens?.()
    router.replace(WebRoutes.Login)
    router.refresh()
  }

  const { getAccessToken, ...rest } = useToken({
    onError: (errorProps) => {
      return props?.noLogout || props?.onError
        ? props.onError?.()
        : handleLogoutByUnauthorized(errorProps?.deleteTokens)
    },
  })

  const _getAccessToken = () => getAccessToken('web', pkg.version)

  const closeSession = (routeToNavigate: WebRoutes = WebRoutes.Home) => {
    initCleanup()
    rest.deleteTokens()
    router.push(routeToNavigate)
    router.refresh()
  }

  return { getAccessToken: _getAccessToken, closeSession, ...rest }
}
