import { logout } from 'authentication'
import { startHolyLoader } from 'holy-loader'
import type { WebRoutes } from '@/shared'
import { useHandlerTokens } from './use-handler-tokens'

export const useLogout = () => {
  const { closeSession, getAccessToken } = useHandlerTokens({ noLogout: true })

  const handleLogout = async (routeToNavigate?: WebRoutes) => {
    startHolyLoader()
    const accessToken = await getAccessToken()

    await logout({ appHeader: 'web', accessToken })
    closeSession(routeToNavigate)
  }

  return { handleLogout }
}
