import { requiredDate } from 'libs'
import { z } from 'zod'
import {
  addressSchema,
  genericPhoneSchema,
  locationSchema,
  userSchema,
} from '@/shared'
import { FormErrorsMessages } from '@/shared/constants'

export enum ItineraryTypeEnum {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
  // SEVERAL_CITIES = 'SeveralCities',
}

export enum PassengerActionType {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  INFANT = 'INFANT',
}

export const citySchema = (message: string) =>
  z.object(
    {
      city: z.string(),
      name: z.string(),
      iataCode: z.string(),
      country: z.string(),
    },
    { required_error: message, invalid_type_error: message }
  )

export const flightsFilterSchema = z
  .object({
    itineraryType: z.nativeEnum(ItineraryTypeEnum),
    originCity: citySchema(FormErrorsMessages.Required),
    destinationCity: citySchema(FormErrorsMessages.Required),
    departureDate: requiredDate({
      requiredMessage: FormErrorsMessages.Required,
    }),
    returnDate: z.date({ coerce: true }).nullish(),
    passengers: z
      .object(
        {
          [PassengerActionType.ADULT]: z
            .number({
              required_error: FormErrorsMessages.Required,
            })
            .max(9, FormErrorsMessages.MaxPassengers),
          [PassengerActionType.CHILD]: z
            .number({
              required_error: FormErrorsMessages.Required,
            })
            .max(9, FormErrorsMessages.MaxPassengers),
          [PassengerActionType.INFANT]: z
            .number({
              required_error: FormErrorsMessages.Required,
            })
            .max(9, FormErrorsMessages.MaxPassengers),
        },
        {
          required_error: FormErrorsMessages.Required,
          invalid_type_error: FormErrorsMessages.Required,
        }
      )
      .refine(
        (passengers) =>
          passengers[PassengerActionType.INFANT] <=
          passengers[PassengerActionType.ADULT],
        {
          message: FormErrorsMessages.AdultGtInfants,
        }
      )
      .refine(
        (passengers) =>
          passengers[PassengerActionType.ADULT] >= 1 ||
          passengers[PassengerActionType.CHILD] >= 1,
        {
          message: FormErrorsMessages.MinPassengers,
        }
      ),
  })
  .superRefine((data, ctx) => {
    if (
      data.itineraryType === ItineraryTypeEnum.ROUND_TRIP &&
      !data.returnDate
    ) {
      ctx.addIssue({
        code: 'custom',
        message: FormErrorsMessages.Required,
        path: ['returnDate'],
      })
    }
  })

//TODO: use this zod schema string in all app
const requiredString = z
  .string({
    required_error: FormErrorsMessages.Required,
  })
  .trim()
  .min(1, { message: FormErrorsMessages.Required })

export const secondaryDocumentSchema = z.object({
  secondaryDocument: z.string().optional(),
  secondaryDocumentNumber: z.string().optional(),
  secondaryExpirationDate: z.string().optional(),
  secondaryDocumentIssuedBy: locationSchema.optional(),
  secondaryDocumentNationality: locationSchema.optional(),
})

export const passengerBaseSchema = userSchema
  .pick({
    firstName: true,
    secondName: true,
    lastName: true,
    secondSurname: true,
    gender: true,
    email: true,
  })
  .merge(
    z.object({
      type: z.nativeEnum(PassengerActionType).optional(),
      consent: z.literal<boolean>(true),
      mainDocument: requiredString,
      documentNumber: requiredString,
      expirationDate: requiredString,
      documentIssuedBy: locationSchema,
      documentNationality: locationSchema,
      hasOptionalDocument: z.boolean().default(false),
      phone: genericPhoneSchema,
      usePersonalData: z.boolean().default(false).optional(),
      dateOfBirth: requiredString,
    })
  )
  .merge(secondaryDocumentSchema)
  .merge(
    z.object({
      country: locationSchema,
      state: locationSchema,
      city: locationSchema,
      address: z.string().optional(),
      postalCode: z.string().optional(),
    })
  )

const contactInfoFormSchema = userSchema
  .pick({
    firstName: true,
    secondName: true,
    lastName: true,
    secondSurname: true,
    email: true,
  })
  .merge(
    z.object({
      phone: genericPhoneSchema,
      usePersonalData: z.boolean().default(false).optional(),
    })
  )
  .merge(addressSchema)

const secondaryDocumentKeys = Object.keys(secondaryDocumentSchema.shape)

export const passengersSchema = ({
  passengers: passengersLength = 1,
}: {
  passengers: number
}) => {
  const schema = z.object({
    passengers: z
      .array(
        passengerBaseSchema.superRefine((data, ctx) => {
          if (data.hasOptionalDocument) {
            secondaryDocumentKeys.forEach((key) => {
              if (!data[key]) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  path: [key],
                  message: FormErrorsMessages.Required,
                })
              }
            })
            if (data.secondaryDocumentNumber === data.documentNumber) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['secondaryDocumentNumber'],
                message: FormErrorsMessages.SameDocumentNumber,
              })
            }
          }
        })
      )
      .length(passengersLength)
      .superRefine((data, ctx) => {
        const documentNumbers = data.map(
          (passenger) => passenger.documentNumber
        )
        const hasSomeDocumentNumberRepeated = documentNumbers.some(
          (documentNumber, index) =>
            documentNumbers.indexOf(documentNumber) !== index
        )
        if (hasSomeDocumentNumberRepeated) {
          data.forEach((_, index) => {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [index, 'documentNumber'],
              message: FormErrorsMessages.DuplicatedDocumentNumber,
            })
          })
        }
        const secondaryDocumentNumbers = data.reduce((acc: string[], item) => {
          if (item.secondaryDocumentNumber) {
            acc.push(item.secondaryDocumentNumber)
          }
          return acc
        }, [])
        const hasSomeSecondaryDocumentNumberRepeated =
          secondaryDocumentNumbers.some(
            (documentNumber, index) =>
              secondaryDocumentNumbers.indexOf(documentNumber) !== index
          )

        if (!hasSomeSecondaryDocumentNumberRepeated) return
        data.forEach((_, index) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [index, 'secondaryDocumentNumber'],
            message: FormErrorsMessages.DuplicatedDocumentNumber,
          })
        })
      }),
    contactInfo: contactInfoFormSchema,
  })
  return schema
}
