'use client'

import { DEVICE_KEY, setDeviceKey } from 'libs'

import { useEffect } from 'react'
import { useListenerToken } from '../hooks/use-listener-token'

export const MaxmindScriptProvider = () => {
  const { cookies } = useListenerToken<{ [DEVICE_KEY]: string }>()
  const deviceKeyCookie = cookies[DEVICE_KEY]

  useEffect(() => {
    if (deviceKeyCookie) return

    void (async () => {
      setDeviceKey()
    })()
  }, [deviceKeyCookie])

  return null
}
