import { z } from 'zod'
import { getIETFEmailSchema, isValidPhoneNumber } from 'libs'
import { getCookie } from 'cookies-next'

const locale = getCookie('NEXT_LOCALE')
const errorsMessages = locale
  ? {
      InvalidOtpCode: 'global.formErrors.invalidOtpCode',
      InvalidPhone: 'global.formErrors.invalidPhone',
      InvalidDeviceInfo: 'global.formErrors.invalidDeviceInfo',
      InvalidEmail: 'global.formErrors.invalidEmail',
      Required: 'global.formErrors.required',
    }
  : {
      Required: 'Campo obligatorio',
      InvalidEmail: 'Correo electrónico invalido',
      InvalidPhone: 'Número de teléfono invalido',
      InvalidOtpCode: 'Código de otp invalido',
      InvalidDeviceInfo: 'Información de dispositivo invalida',
    }

export const loginFormSchema = z.union([
  z.object({
    email: z
      .string({
        invalid_type_error: errorsMessages.Required,
        required_error: errorsMessages.Required,
      })
      .trim()
      .min(1, { message: errorsMessages.Required })
      .email({ message: errorsMessages.InvalidEmail })
      .pipe(
        getIETFEmailSchema({
          invalidMessage: errorsMessages.InvalidEmail,
          requiredMessage: errorsMessages.Required,
        })
      ),
  }),
  z.object({
    phone: z
      .string({
        required_error: errorsMessages.Required,
      })
      .trim()
      .min(3, { message: errorsMessages.Required })
      .transform((value) => value.replaceAll(' ', '').replace(/[()-]/g, ''))
      // TODO: Improve and refactor this logic
      .refine(
        (value) => {
          const formattedValue = value.includes('+1') ? value : `+1${value}`
          return isValidPhoneNumber(formattedValue)
        },
        {
          message: errorsMessages.InvalidPhone,
        }
      )
      .transform((value) => {
        const formattedValue = value.includes('+1') ? value : `+1${value}`
        return formattedValue
      }),
  }),
])

export const deviceSchema = z.object({
  device: z.object({
    appVersion: z.string({
      invalid_type_error: errorsMessages.Required,
      required_error: errorsMessages.Required,
    }),
    deviceKey: z
      .string({
        invalid_type_error: errorsMessages.Required,
        required_error: errorsMessages.Required,
      })
      .min(1, errorsMessages.Required),
    model: z.string({
      invalid_type_error: errorsMessages.Required,
      required_error: errorsMessages.Required,
    }),
    operatingSystem: z.string({
      invalid_type_error: errorsMessages.Required,
      required_error: errorsMessages.Required,
    }),
    type: z.string({
      invalid_type_error: errorsMessages.Required,
      required_error: errorsMessages.Required,
    }),
    fcmToken: z
      .string({
        invalid_type_error: errorsMessages.Required,
        required_error: errorsMessages.Required,
      })
      .optional(),
  }),
})

export const otpCodeSchema = z.object({
  otpCode: z
    .string({
      invalid_type_error: errorsMessages.Required,
      required_error: errorsMessages.Required,
    })
    .min(6, errorsMessages.InvalidOtpCode)
    .max(6, errorsMessages.InvalidOtpCode),
})

export const loginSchema = loginFormSchema.and(
  deviceSchema.merge(otpCodeSchema)
)

export const requestOtpCodeSchema = loginFormSchema.and(deviceSchema)
