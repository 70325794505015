'use client'

import { useRef, useState, useTransition } from 'react'
import { toast } from 'ui'
import {
  deleteNotification,
  getNotificationHistory,
  markReadNotification,
  type NotificationsHistoryInfo,
} from '../services'
import { useOpenNotificationCard } from './use-open-notification-card'

const defaultPage = 1
const defaultTake = 10
export const useNotification = ({
  notificationCount,
  getAccessToken,
  appHeader = 'web',
  locale = 'es',
}: {
  notificationCount?: number
  getAccessToken: () => Promise<string | undefined>
  appHeader?: 'panel' | 'web'
  locale?: 'es' | 'en'
}) => {
  const [count, setCount] = useState(notificationCount ?? 0)
  const [notifications, setNotifications] = useState<
    NotificationsHistoryInfo['notifications']
  >([])
  const { openCard, setOpenCard } = useOpenNotificationCard()
  const [disabledLoadMoreButton, setDisabledLoadMoreButton] = useState(false)
  const [isLoading, startTransition] = useTransition()

  const currentPage = useRef(1)
  const currentHasNextPage = useRef(false)

  const loadNotifications = (params?: {
    page?: number
    merge?: boolean
    take?: number
  }) => {
    // @ts-ignore
    startTransition(async () => {
      const accessToken = await getAccessToken()

      if (!accessToken) return

      const { data, success } = await getNotificationHistory({
        appHeader,
        locale,
        accessToken,
        queryParams: {
          order: 'DESC',
          take: params?.take ?? defaultTake,
          page: params?.page ?? defaultPage,
        },
      })

      if (!success) return

      const { notifications: newNotifications, hasNextPage } = data
      const currentNotifications = params?.merge
        ? [...notifications, ...newNotifications]
        : newNotifications

      setCount(data.unreadedCount)
      setNotifications(currentNotifications)
      setDisabledLoadMoreButton(!hasNextPage)
      currentHasNextPage.current = hasNextPage
    })
  }

  // @note Execute loadNotifications on mount
  const handleOpenCard = async (open: boolean) => {
    setOpenCard(open)

    if (!open) {
      setDisabledLoadMoreButton(!currentHasNextPage.current)
      setNotifications([])
    } else {
      loadNotifications({
        take: notifications.length || defaultTake,
      })
    }
  }

  // @note Execute when user click over load more button
  const handleLoadMoreNotifications = async () => {
    if (disabledLoadMoreButton) return
    const page = currentPage.current + 1
    currentPage.current = page
    loadNotifications({ merge: true, page })
  }

  // @note Execute when user click over delete button
  const handleDeleteNotification = async ({
    id,
    isRead,
  }: {
    id: string
    isRead: boolean
  }) => {
    const accessToken = await getAccessToken()

    if (!accessToken) return

    const { success, message } = await deleteNotification({
      accessToken,
      notificationId: id,
      appHeader,
      locale,
    })
    if (!success) return

    if (!isRead) {
      setCount((prevCount) => prevCount - 1)
    }
    loadNotifications({
      take:
        notifications.length <= defaultTake
          ? defaultTake
          : notifications.length - 1,
    })
    currentPage.current = 1

    toast({
      description: message,
    })
  }

  // @note Execute when user click over notification
  const handleOpenNotification = async (
    notification: NotificationsHistoryInfo['notifications'][number]
  ) => {
    handleOpenCard(false)

    if (notification.read) return

    const accessToken = await getAccessToken()
    const { success } = await markReadNotification({
      notificationId: notification.id,
      accessToken,
      appHeader,
      locale,
    })

    if (success) {
      setCount((prevCount) => prevCount - 1)
    }
  }

  return {
    states: {
      count,
      notifications,
      isLoading,
      openCard,
      disabledLoadMoreButton,
    },
    actions: {
      setOpenCard,
      handleOpenCard,
      handleLoadMore: handleLoadMoreNotifications,
      handleDeleteNotification,
      handleOpenNotification,
    },
  }
}
