import { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { addSeconds } from 'date-fns'
import { getOtpTime } from 'libs'

export const useOtpTimer = () => {
  const [isOtpExpired, setIsOtpExpired] = useState(false)

  const {
    seconds: otpSeconds,
    minutes: otpMinutes,
    start: startTimerOtp,
    restart: reStartTimerOtp,
    pause: pauseOtp,
    isRunning: isRunningOtp,
  } = useTimer({
    expiryTimestamp: addSeconds(new Date(), getOtpTime(true)),
    onExpire: () => {
      setIsOtpExpired(true)
    },
    autoStart: false,
  })

  const restartTimer = () => {
    reStartTimerOtp(addSeconds(new Date(), getOtpTime(true)))
  }
  useEffect(() => {
    startTimerOtp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    states: {
      otpSeconds: otpSeconds,
      otpMinutes: otpMinutes,
      isOtpExpired,
      isRunningOtp,
    },
    actions: {
      restartTimer,
      setIsOtpExpired,
      pauseOtp,
      startTimerOtp,
    },
  }
}
