import { deleteCookie, getCookies, setCookie } from 'cookies-next'
import {
  ACCESS_TOKEN,
  ApiError,
  DEVICE_KEY,
  FCM_TOKEN,
  REFRESH_TOKEN,
  decodeTokens,
  decrypt,
  encrypt,
  getBrowser,
  getCookieExpireTime,
  getDeviceType,
  getOS,
  verifyToken,
} from 'libs'
import { postRefreshTokens } from '../services/login.service'
import { PostRefreshBody } from '../types/login.types.'

export const useToken = (handler?: {
  onError?: (props?: { error: unknown; deleteTokens: () => void }) => void
}) => {
  const {
    [ACCESS_TOKEN]: accessToken,
    [REFRESH_TOKEN]: refreshToken,
    [DEVICE_KEY]: deviceKey,
    [FCM_TOKEN]: fcmToken,
  } = getCookies()

  const updateTokens = ({
    accessToken,
    refreshToken,
    fcmToken,
  }: {
    refreshToken: string
    accessToken: string
    fcmToken?: string
  }) => {
    const { accessTokenPayload, refreshTokenPayload } = decodeTokens({
      accessToken,
      refreshToken,
    })

    setCookie(ACCESS_TOKEN, accessToken, {
      expires: getCookieExpireTime(accessTokenPayload.exp),
    })

    setCookie(REFRESH_TOKEN, refreshToken, {
      expires: getCookieExpireTime(refreshTokenPayload.exp),
    })

    if (fcmToken) {
      const encryptedFcmToken = encrypt(fcmToken)
      setCookie(FCM_TOKEN, encryptedFcmToken, {
        expires: getCookieExpireTime(refreshTokenPayload.exp),
      })
    }
  }

  const deleteTokens = () => {
    deleteCookie(ACCESS_TOKEN)
    deleteCookie(REFRESH_TOKEN)
    deleteCookie(DEVICE_KEY)
    deleteCookie(FCM_TOKEN)
  }
  const getAccessToken = async (
    appHeader: 'panel' | 'web' = 'web',
    version: string
  ) => {
    try {
      const { access_token, refresh_token } = getCookies()

      if (!access_token && !refresh_token) {
        throw new Error('Tokens missing')
      }

      if (!access_token && refresh_token) {
        const decryptedDeviceKey = deviceKey || null
        const decryptedFcmToken = fcmToken ? decrypt(fcmToken) : null

        const userAgent = navigator.userAgent

        const refreshBody: PostRefreshBody = {
          deviceKey: decryptedDeviceKey,
          fcmToken: decryptedFcmToken,
          appVersion: version,
          model: getBrowser(userAgent),
          operatingSystem: getOS(userAgent),
          type: getDeviceType(userAgent),
        }

        const {
          success,
          data: tokens,
          errors,
          statusCode,
          message,
        } = await postRefreshTokens({
          body: refreshBody,
          appHeader,
          refreshTokenValue: refresh_token,
        })

        if (!success) {
          throw new ApiError(
            message,
            errors,
            statusCode,
            JSON.stringify(errors)
          )
        }

        updateTokens({
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
        })

        return tokens.accessToken
      }

      if (access_token) await verifyToken(access_token)

      return access_token as string
    } catch (error) {
      handler?.onError && handler.onError({ deleteTokens, error })
    }
  }

  return {
    getAccessToken,
    deleteTokens,
    updateTokens,
    accessToken,
    refreshToken,
  }
}
